import { useEffect, useMemo, useState } from 'react';
import {
  VStack,
  useToast,
  Box,
  SimpleGrid,
  Button,
  Checkbox,
  Heading,
  Text,
} from '@chakra-ui/react';
import { SelectInput, TextInput } from '@/components';
import { api } from '@/lib';
import { useUserStore } from '@/stores';
import { useInstanceQuery, useUnidadesIdQuery } from '@/hooks';
import axios from 'axios';
import { ButtonsForm } from '../ButtonsForm';

interface Props {
  patient: any;
  onCancel: () => void;
}

export function AssociatePatientForm({ patient, onCancel }: Props) {
  const user = useUserStore(state => state.user);
  const { data: instances = [], refetch: refreshInstance } = useInstanceQuery();
  const [instanciaId, setInstanciaId] = useState(instances[0]?.value);
  const { data: units = [] } = useUnidadesIdQuery(instanciaId);
  const [callModalVisible, setCallModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState('');
  const [checkBoxTerms, setCheckBoxTerms] = useState(false);
  const [unidades, setUnidades] = useState('');
  const [vincularLoading, setVincularLoading] = useState(false);
  const [unidadeSelect, setUnidade] = useState('');

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  useEffect(() => {
    setCode('');
    setUnidade('');
    setUnidades('');
    setCheckBoxTerms(false);
  }, []);

  useEffect(() => {
    setUnidades('');
    setUnidade('');
    setCheckBoxTerms(false);
  }, [code]);

  const getUnidadesPro = async () => {
    if (!code) {
      toast({
        title: 'Ops, algo deu errado. O código é obrigatório',
        status: 'error',
      });
      return;
    }
    setIsLoading(true);
    try {
      const { data: response } = await api.get(
        '/instancia/unidade-get-codigo-associacao',
        {
          params: {
            codigo: code,
          },
        },
      );
      setUnidades(response.data);
    } catch (err) {
      toast({
        title: 'Erro ao buscar unidades de saúde desse profissional',
        status: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const vincularCode = async () => {
    if (!unidadeSelect) {
      toast({
        title: 'Ops, selecione uma unidade de Saúde',
        status: 'error',
      });
      return;
    }

    if (!checkBoxTerms) {
      toast({
        title:
          'Para prosseguir é necessario aceitar os termos de uso e política de privacidade',
        status: 'error',
      });
      return;
    }

    setVincularLoading(true);
    try {
      await api.post(
        `/paciente/vincular-paciente-new?idUnidade=${unidadeSelect}&idPaciente=${patient.id}&codigo=${code}`,
      );
      toast({
        title: 'Paciente vinculado ao Profissional de Saúde',
        status: 'success',
      });
      onCancel();
    } catch (e) {
      let message;
      if (axios.isAxiosError(e)) {
        const { response: res } = e;
        message = res?.data?.errors[0];
      }

      toast({
        title: 'Não é possível se vincular a esse Profissional de Saúde',
        status: 'error',
      });
    } finally {
      setVincularLoading(false);
    }
  };

  return (
    <Box>
      <VStack spacing="4">
        <Text
          color="brand-dark.300"
          fontSize="md"
          fontWeight="medium"
          textAlign="left"
          width="100%"
          display="flex"
        >
          <Text fontWeight="bold" mr={1}>
            PACIENTE:{' '}
          </Text>{' '}
          {patient.nome}
        </Text>

        <TextInput
          placeholder=""
          label="Código Profissional"
          maxLength={15}
          value={code}
          onChange={e => setCode(e.target.value)}
        />

        {unidades && unidades?.length > 0 ? (
          <>
            <SelectInput
              label="Unidades de Saúde"
              options={units}
              value={unidadeSelect}
              placeholder="Selecione unidade de saúde"
              onChange={e => setUnidade(e.target.value)}
            />

            <VStack spacing="4" w="100%" mb="8" alignItems="flex-start">
              <Checkbox
                colorScheme="green"
                fontSize="xs"
                fontWeight="medium"
                onChange={e => setCheckBoxTerms(e.target.checked)}
              >
                Eu li e concordo com os Termos de Uso e Política de privacidade
              </Checkbox>
            </VStack>

            <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
              <Button
                type="button"
                onClick={onCancel}
                variant="outline"
                colorScheme="brand-orange"
                h="45px"
                borderRadius="28px"
                color="brand-orange.300"
                fontSize="xs"
                fontWeight="medium"
                textTransform="uppercase"
              >
                Voltar
              </Button>
              <Button
                type="button"
                onClick={vincularCode}
                colorScheme="brand-green"
                h="45px"
                borderRadius="28px"
                color="white"
                fontSize="xs"
                fontWeight="medium"
                textTransform="uppercase"
                isLoading={vincularLoading}
                disabled={vincularLoading}
              >
                Vincular
              </Button>
            </SimpleGrid>
          </>
        ) : (
          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <Button
              type="button"
              onClick={onCancel}
              variant="outline"
              colorScheme="brand-orange"
              h="45px"
              borderRadius="28px"
              color="brand-orange.300"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Voltar
            </Button>
            <Button
              type="button"
              onClick={getUnidadesPro}
              colorScheme="brand-green"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
              isLoading={isLoading}
              disabled={isLoading}
            >
              Buscar
            </Button>
          </SimpleGrid>
        )}
      </VStack>
    </Box>
  );
}
